<template>
  <footer id="footer" class="p-0 mt-5 secondary">
    <div class="footer-top container-prosker">
      <v-row class="w-100 p-2" :class="$vuetify.breakpoint.mobile ? 'm-0' : ''">
        <v-col cols="12" md="4" :class="$vuetify.breakpoint.mobile ? 'text-center' : ''">
          <router-link :to="{ name: 'social.list' }">
              <img
                  width="100"
                  src="@/assets/images/logo/logoFooter.png"
                  class="img-fluid ml-2 mb-5"
                  alt="logo"
              />
            </router-link>
            <p>{{  $t("uniteProsk") }}</p>
            <div class="social-links my-3">
              <v-btn color="#59D79D" class="me-3" fab dark :href="instagramUrl" target="_blank">
                <v-icon color="#3F44A6">mdi-instagram</v-icon>
              </v-btn>
              <v-btn color="#59D79D" class="me-3" fab dark :href="facebookUrl" target="_blank">
                <v-icon color="#3F44A6">mdi-facebook</v-icon>
              </v-btn>
              <v-btn color="#59D79D" class="me-3" fab dark href="https://www.linkedin.com/company/prosk/" target="_blank">
                <v-icon color="#3F44A6">mdi-linkedin</v-icon>
              </v-btn>
            </div>
          <v-row class="align-start">
            <v-col cols="12" md="6">
              <v-text-field
                :placeholder="$t('registerYourEmail')"
                :hide-details="errorEmail.length === 0"
                :error-count="errorEmail.length"
                :error-messages="errorEmail"
                v-model="email"
                color="text_contrast_to_background"
                outlined
                class="custom-text-field"
            ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <general-button  white :action="subscribeNewsletter" :loading="false" :message="$t('subscribeCta')" size="medium"  cssClasses="w-100 mt-1"/>
            </v-col>
            <v-col cols="12" class="pt-0">
              <p class="font-size-12 mt-0">{{ $t('subscribeToNewsletter') }}</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3" :class="$vuetify.breakpoint.mobile ? 'text-center' : ''">
          <p class="primary--text h5">{{ $t('hireAService') }}</p>
          <v-col cols="12" class="py-2 pl-0" v-for="(service, i) in hireServices" :key="i">
            <router-link v-if="service.params" :to="{
              name: 'social.users',
              params: { query: JSON.stringify(globalSearchObject) }
            }">
              <span class="white--text">{{ $t(service.title) }}</span>
            </router-link>
            <router-link v-else :to="{ name: service.link, hash: service.hash }">
              <span class="white--text">{{ $t(service.title) }}</span>
            </router-link>
          </v-col>
        </v-col>
        <v-col cols="12" md="3" :class="$vuetify.breakpoint.mobile ? 'text-center' : ''">
          <p class="primary--text h5">{{ $t('offerAService') }}</p>
          <v-col cols="12" class="py-2 pl-0" v-for="(service, i) in offerServices" :key="i">
            <router-link :to="{ name: service.link, hash: service.hash }">
              <span class="white--text">{{ $t(service.title) }}</span>
            </router-link>
          </v-col>
        </v-col>
        <v-col cols="12" md="2" :class="$vuetify.breakpoint.mobile ? 'text-center' : ''">
            <p class="primary--text h5">{{ $t('needSomeHelp') }} ?</p>
            <v-col cols="12" class="py-2 pl-0" v-for="(help, i) in helps" :key="'help' + i">
              <router-link :to="{ name: help.link }">
                <span class="white--text">{{ $t(help.title) }}</span>
              </router-link>
            </v-col>
        </v-col>
        <v-col cols="12" md="6" class="text-left  py-0" v-if="!this.$i18n.locale.toLowerCase().includes('br')">
          {{ $t('madeWith')}}<v-icon color="red" class="mx-1" small>mdi-heart</v-icon>{{ $t('inUruguay')}}
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="text-left text-md-right py-0"
          :class="$vuetify.breakpoint.mobile ? 'text-center' : ''"
        >
          Copyright PROSK SAS
        </v-col>
      </v-row>
    </div>
  </footer>
</template>
<script>
// import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import userDataMixin from '@/mixins/userDataMixin';
import axios from 'axios';

export default {
  name: 'DefaultFooter',
  components: { GeneralButton },
  mixins: [userDataMixin],
  data () {
    return {
      globalSearchObject: {
        user: null,
        categoryIds: []
      },
      errorEmail: [],
      email: null,
      loading: false,
      helps: [
        {
          title: 'help',
          link: 'social.contact-page'
        }
      ],
      offerServices: [
        {
          title: 'howDoesItWork',
          link: 'social.subscription-page'
        },
        {
          title: 'joinAsProsker',
          link: 'social.proskers-page'
        },
        {
          title: 'faqs',
          link: 'social.subscription-page',
          hash: '#faqs-section'
        }
      ],
      hireServices: [
        {
          title: 'howDoesItWork',
          link: 'social.how-works-clients'
        },
        {
          title: 'categories',
          link: 'social.categories-page'
        },
        {
          title: 'searchProsker',
          link: 'social.users',
          params: true
        },
        {
          title: 'faqs',
          link: 'social.how-works-clients',
          hash: '#faqs-section'
        }
      ],
      information: [
        {
          title: 'us',
          link: 'social.us'
        },
        {
          title: 'termsAndConditions',
          link: 'social.terms-and-conditions'
        }
      ],
      items: [
        {
          icon: 'mdi-map-marker',
          text: 'Av. Rivera 2248, Montevideo - Uruguay'
        },
        {
          icon: 'mdi-email-outline',
          text: process.env.VUE_APP_LOCATION.includes('br') ? 'atendimento@prosk.com.br' : 'info@prosk.com.uy'
        },
        {
          icon: 'mdi-phone',
          text: '(+598) 98702882'
        }
      ],
      instagramUrl: process.env.VUE_APP_LOCATION.includes('br') ? 'https://www.instagram.com/prosk.br' : 'https://instagram.com/prosk.uy?igshid=YmMyMTA2M2Y=',
      facebookUrl: process.env.VUE_APP_LOCATION.includes('br') ? 'https://www.facebook.com/ProskBrasil' : 'https://www.facebook.com/prosk.com.uy/'
    };
  },
  methods: {
    validEmail: function (email) {
      const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    resetErrors () {
      this.errorEmail = [];
    },
    reset () {
      this.email = null;
    },
    sendDataNewsLayerInfo () {
      this.trackUserData('generate_lead', {
        lead_type: 'newsletter'
      });
    },
    subscribeNewsletter () {
      this.resetErrors();
      if (this.email && this.validEmail(this.email)) {
        this.loading = true;
        const data = {
          email: this.email
        };
        const url = `${process.env.VUE_APP_BASE_URL}/api/newsletter-subscriptions`;
        axios
            .post(url, data)
            .then(() => {
              this.sendDataNewsLayerInfo();
              this.reset();
              this.$swal.fire({
                title: this.$t('awesome'),
                text: this.$t('thankForSubscribe'),
                confirmButtonColor: '#19AD93'
              });
              this.email = '';
              this.loading = false;
            })
            .catch((error) => {
              this.email = '';
              this.loading = false;
              this.showError(error);
            });
      }
      if (!this.email) {
        this.errorEmail.push(this.$t('emailRequired'));
      }
      if (!this.validEmail(this.email)) {
        this.errorEmail.push(this.$t('enterValidEmail'));
      }
    }
  }
};
</script>
<style scoped>
.custom-text-field ::v-deep .v-input__control {
  border-radius: 16px !important;
  padding: 0 !important;

}

.custom-text-field ::v-deep .v-input__slot {
  border-radius: 16px !important;
  padding: 0 16px !important;

}

.custom-text-field ::v-deep .v-text-field__slot {
  border-radius: 16px !important;
}

.custom-text-field ::v-deep .v-text-field__slot input{
  color:#212833 ;
  padding: 0 !important;
  color:white;
}

.custom-text-field ::v-deep .v-text-field__slot input::placeholder {
  color:white;
}

.custom-text-field ::v-deep .v-input__slot fieldset{
  top:1px !important;
  bottom: 1px !important;
  border-color: #606A76 !important;
  background-color: #FFFFFF26;
}

.custom-text-field ::v-deep .v-input__slot{
  background-color: transparent !important;
}

</style>
