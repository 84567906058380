<template>
  <div>
    <header class="prosk-navbar" :class="getClassBasedOnRoute">
      <v-row class="container-prosker m-auto">
        <v-col :md="currentUser ? 3 : 2" cols="6" class="d-flex align-items-center">
            <router-link :to="{ name: currentUser && currentUser.type_user === $options.USER_TYPE_CLIENT ? 'social.clients-page' : 'social.proskers-page' }">
              <img src="../../../assets/images/logo/logo-prosk-blue.png" class="img-fluid" width="190" alt="logo"/>
            </router-link>
        </v-col>
        <v-col :md="6" cols="6" class="d-md-flex d-none justify-content-start align-items-center" order="2" order-md="1">
          <div class="d-md-flex d-none align-items-center justify-content-end fill-height">
            <router-link text small class="font-weight-bold dark_blue--text px-3 text-center font-size-16 font-family-jakarta" to="/categorias">{{$t('categories') }}
            </router-link>
            <router-link text small class="font-weight-bold dark_blue--text px-3 text-center font-size-16 " to="/perfiles">{{$t('searchProsker') }}
            </router-link>
            <router-link class=" font-weight-bold dark_blue--text px-3 text-center font-size-16 " :to="{ name: 'social.proskers-page' }">
              {{ $t('joinAsProsker')}}
            </router-link>
            <router-link v-if="blogUrl" text small class="font-weight-bold dark_blue--text px-3 text-center font-size-16" :to="{ name: '' }" :href="blogUrl" target="_blank" rel="noopener noreferrer">{{$t('blog') }}
            </router-link>
            <router-link text small class="font-weight-bold dark_blue--text px-3 text-center font-size-16 " :to="{ name: 'social.contact-page' }">{{$t('contact') }}
            </router-link>
          </div>
        </v-col>
        <v-col  class="d-flex flex-nowrap justify-content-end align-items-center " order="1" order-md="2">
          <div>
            <v-btn fab elevation="0" color="transparent" @click="toogleSearch">
              <v-icon color="black"> mdi-magnify </v-icon>
            </v-btn>
          </div>
          <div  v-show="!currentUser">
            <router-link
              text
              small
              class="dark_blue--text d-md-block d-none px-3 text-center font-weight-bold font-size-16"
              :to="{ name: 'auth1.sign-in1' }"
            >
              {{ $t('logIn')}}
            </router-link>
          </div>
          <v-btn
            text
            :small="$vuetify.breakpoint.mobile"
            :class="$vuetify.breakpoint.mobile ? 'px-2 secondary bt-default-shape' : 'secondary bt-default-shape'"
            v-show="!currentUser"
            :to="{ name: 'auth1.sign-up1' }"
          >
            <span class="py-2  text-capitalize">{{$t('signUp') }}</span>
          </v-btn>
          <v-menu v-if="currentUser" offset-y :close-on-content-click="showMenu" location="bottom" content-class="border-radius-medium">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="profile-button" icon v-bind="attrs" v-on="on">
                <v-avatar>
                  <img
                    v-if="!currentUser.profile_img"
                    src="@/assets/images/page-img/avatar.png"
                    class="rounded-circle avatar-50"
                    alt="user"
                  />
                  <img
                    v-else
                    :src="currentUser.profile_img"
                    class="rounded-circle avatar-50"
                    alt="user"
                  />
                </v-avatar>
              </v-btn>
            </template>
            <v-card class="mx-auto p-2 border-radius-medium" max-width="300" min-width="240" >
              <v-list
                dense
                flat
              >
              <v-list-item>
                <img
                    v-if="!currentUser.profile_img"
                    src="@/assets/images/page-img/avatar.png"
                    class="rounded-circle avatar-50"
                    alt="user"
                  />
                  <img
                    v-else
                    :src="currentUser.profile_img"
                    class="rounded-circle avatar-50"
                    alt="user"
                  />
                  <div class="ml-2">
                  <p class="m-0">Bienvenido!</p>
                  <p class="m-0 secondary--text font-weight-bold">{{ currentUser.username }}</p>
                </div>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item-group>
                  <v-list-item
                    v-show="currentUser && currentUser.type_user === $options.USER_TYPE_CLIENT"
                    v-for="(item, i) in currentMenu"
                    :key="i"
                    :to="{ name: item.path, params: item.params }"
                    exact-path
                    @click="showMenu = true"
                  >
                    <v-list-item-content>
                      <span class=" main_text_color--text">
                        {{ $t(item.title) }}
                      </span>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <img :src="require(`@/assets/images/page-img/${item.icon }`)" >
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-group
                    v-show="currentUser && !(currentUser.type_user === $options.USER_TYPE_CLIENT)"
                    :value="true"
                    class="secondary-prosk"
                  >
                    <template v-slot:activator>
                      <v-list-item-content @click="showMenu = false" class="main_text_color--text font-weight-bold">
                        {{ $t("office") }}
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in currentMenu"
                      :key="item.title"
                      @click="showMenu = true"
                      :class="getActivatedMenu(item)"
                    >
                      <v-list-item-content >
                        <router-link
                          :to="{ name: item.path, params: item.params, query: item.query }"
                          exact-path
                          :class="getTextColor(item)"
                        >
                          {{ $t(item.title) }}
                        </router-link>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <img :src="require(`@/assets/images/page-img/${item.icon }`)" >
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-group>
                  <v-list-group v-show="purchaseMenu.length">
                    <template v-slot:activator>
                      <v-list-item-content @click="showMenu = false" class="main_text_color--text font-weight-bold">
                        {{ $t("purchases") }}
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in purchaseMenu"
                      :key="item.title"
                      @click="showMenu = true"
                      :class="getActivatedMenu(item)"
                    >
                      <v-list-item-content>
                        <router-link
                          :to="{ name: item.path, params: item.params }"
                          class="main_text_color--text"
                        >
                          {{ $t(item.title) }}
                        </router-link>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <img :src="require(`@/assets/images/page-img/${item.icon }`)" >
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-group>
                  <v-list-group v-if="(currentUser && currentUser.type_user) === $options.USER_TYPE_ADMIN">
                    <template v-slot:activator>
                      <v-list-item-content @click="showMenu = false" class="main_text_color--text font-weight-bold">
                        {{ $t("admin") }}
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in menuAdmin"
                      :key="item.title"
                      @click="showMenu = true"
                      :class="getActivatedMenu(item)"
                    >
                      <v-list-item-content>
                        <router-link
                          :to="{ name: item.path }"
                          class="main_text_color--text"
                        >
                          {{ $t(item.title) }}
                        </router-link>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <img :src="require(`@/assets/images/page-img/${item.icon}`)" />
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-group>
                  <v-divider />
                  <v-list-item @click="signOut">
                    <v-list-item-content>
                      <span class="main_text_color--text">
                        {{ $t("signOut") }}
                      </span>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small class="secondary-prosk"
                        >mdi-logout</v-icon
                      >
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="container-prosker m-auto" v-if="!currentUser">
        <v-col cols="1"  class="d-md-none d-flex align-items-center">
              <v-menu  offset-y location="bottom" :close-on-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="black">mdi-menu</v-icon>
                  </v-btn>
                </template>
                <v-card class="mx-auto" tile>
                  <v-list dense flat>
                    <v-list-item-group>
                      <v-list-item>
                        <router-link class="secondary-prosk d-flex align-items-center" text :to="{ name: 'social.proskers-page' }">
                          {{ $t('joinAsProsker')}}
                        </router-link>
                      </v-list-item>
                      <v-list-item>
                        <router-link text class="secondary-prosk" :to="{ name: 'auth1.sign-up1' }">
                          {{ $t('signUp') }}
                        </router-link>
                      </v-list-item>
                      <v-list-item>
                        <router-link  text class="secondary-prosk" :to="{ name: 'auth1.sign-in1' }">
                          {{ $t('logIn') }}
                        </router-link>
                      </v-list-item>
                      <v-list-item>
                        <router-link text class="secondary-prosk" :to="{ name: 'social.categories-page' }">
                          {{ $t('categories') }}
                        </router-link>
                      </v-list-item>
                      <v-list-item>
                        <router-link text class="secondary-prosk" :to="{ name: 'social.users',
                        params: { query: JSON.stringify(globalSearchObject) } }">
                          {{ $t('searchProsker') }}
                        </router-link>
                      </v-list-item>
                      <v-list-item v-if="blogUrl">
                        <router-link text class="secondary-prosk" :to="{ name: '' }" :href="blogUrl" target="_blank" rel="noopener noreferrer">
                          {{ $t('blog') }}
                        </router-link>
                      </v-list-item>
                      <v-list-item>
                        <router-link text class="secondary-prosk" :to="{ name: 'social.contact-page' }">
                          {{ $t('help') }}
                        </router-link>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-menu>
        </v-col>
      </v-row>
    </header>
    <v-row v-show="showSearch" class="container-prosker m-auto">
      <v-col cols="12" class="d-flex align-items-center">
          <v-text-field
            :placeholder="this.$t('searchHere') + ' ...'"
            v-model="search"
            filled
            rounded
            dense
            hide-details
            color="black"
            @keyup.enter.native="sendGlobalSearch"
            v-show="$route.name !== 'social.contact-page'"
          >
            <template v-slot:prepend-inner>
              <v-icon color="black"> mdi-magnify </v-icon>
            </template>
          </v-text-field>
        </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { hashData } from '@/misc/utils';
import { USER_TYPE_CLIENT, USER_TYPE_ADMIN, USER_TYPE_PROSKER, FEATURE_JOBS, FEATURE_SUBSCRIPTIONS,
  HOME_ROUTE, CALENDAR_TAB, AES_SECRET } from '@/misc/constants';
import { isFeatureEnabled } from '@/misc/featureFlagService';

export default {
  name: 'NavBar',
  components: {
  },
  data () {
    return {
      showSearch: false,
      showMenu: false,
      search: '',
      globalSearchObject: {},
      globalUserObject: {},
      currentMenu: [],
      purchaseMenu: [],
      hashedId: '',
      menuLeft: [
        // { title: 'home', path: '/', value: 1 },
        { title: 'categories', path: '/categorias', value: 2 },
        { title: 'searchProsker', value: 3 },
        { title: 'favorites', path: '/mis-favoritos', value: 4 },
        { title: 'contact', path: '/contacto', value: 5 }
      ],
      menuAdmin: [
        {
          title: 'adminUsers',
          path: 'social.users-information',
          icon: 'usuarios.svg',
          enabled: (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_ADMIN)
        },
        {
          title: 'adminCategories',
          path: 'social.categories',
          icon: 'categorias.svg',
          enabled: (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_ADMIN)
        },
        { title: 'adminSkills',
          path: 'social.skills',
          icon: 'modalidades.svg',
          enabled: (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_ADMIN)
        },
        { title: 'adminServices',
          path: 'social.jobs-admin',
          icon: 'servicios.svg',
          enabled: (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_ADMIN)
        },
          { title: 'adminCoupons',
          path: 'admin.coupons',
          icon: 'servicios.svg',
          enabled: (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_ADMIN)
        },
        { title: 'adminCancellationReason',
          path: 'social.cancellation-reason',
          icon: 'cancelaciones.svg',
          enabled: (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_ADMIN)
        }
      ]
    };
  },
  watch: {
    getSearchText: function (value) {
      if (value) {
        this.setSearch();
      }
    },
      search: function (value) {
        if (!value) {
            this.globalSearchObject.query = null;
            this.sendGlobalSearch();
        }
      }
  },
  created () {
    this.setImportedConstants();
    this.hashedId = this.currentUser ? hashData(this.currentUser.id.toString(), this.$options.AES_SECRET) : '';
    this.currentMenu = (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_CLIENT) ? this.getCustomerMenu() : this.getProskerMenu();
    this.currentMenu = this.currentMenu.filter(item => item.enabled);
    this.purchaseMenu = (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_CLIENT) ? [] : this.getPurchaseMenu();
    this.purchaseMenu = this.purchaseMenu.filter(item => item.enabled);
    this.setSearchTextFromUrl();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),
    getSearchText () {
      return this.$store.getters['search/searchText'];
    },
    currentRouteName () {
        return this.$route.name;
    },
    getClassBasedOnRoute () {
      const currentPath = this.$route.name;
      const routesWithoutBackground = ['social.proskers-page'];
      if (routesWithoutBackground.includes(currentPath)) {
        return '';
      }
      return 'bg-white shadow-sm'
    },
    blogUrl () {
      return process.env.VUE_APP_PROSK_BLOG_URL;
    }
  },
  methods: {
    toogleSearch () {
      this.showSearch = !this.showSearch;
    },
    getProskerMenu () {
      return [
        { title: 'home', path: 'social.list', icon: 'modalidades.svg', enabled: true },
        {
          title: 'myAccount',
          path: 'social.user-profile',
          params: { query: `${(this.currentUser || {}).username}-${this.hashedId}` },
          icon: 'mi_cuenta.svg',
          enabled: true
        },
        {
          title: 'myJobs',
          path: 'social.services',
          params: { query: 'mis-servicios' },
          icon: 'servicios.svg',
          enabled: this.$options.JOBS_ENABLED
        },
        { title: 'messages', path: 'app.chat', params: { conversationId: '0' }, icon: 'mensajes.svg', enabled: true },
        { title: 'calendar',
          path: 'social.user-profile',
          params: { query: `${(this.currentUser || {}).username}-${this.hashedId}` },
          query: { selectedTab: this.$options.CALENDAR_TAB },
          icon: 'agenda.svg',
          enabled: this.$options.USER_TYPE_CLIENT || ((this.currentUser || { active_subscription: [] }).active_subscription.filter(item => item.name === 'Premium' || item.name === 'Free Trial').length > 0)
        },
        {
          title: 'subscription',
          path: 'social.user-subscription',
          icon: 'suscripcion.svg',
          enabled: this.$options.SUBSCRIPTIONS_ENABLED
        },
        { title: 'contact', path: 'social.contact-page', icon: 'ayuda.svg', enabled: true }
      ];
    },
    getPurchaseMenu () {
      return [
        {
          title: 'servicesMenu',
          path: 'social.services',
          params: { query: 'servicios-contratados' },
          icon: 'servicios.svg',
          enabled: this.$options.JOBS_ENABLED
        },
        { title: 'favorites', path: 'social.favorites', icon: 'favoritos.svg', enabled: true },
        {
          title: 'categories',
          path: 'social.categories-page',
          icon: 'categorias.svg',
          enabled: true
        }
      ]
    },
    getCustomerMenu () {
      return [
        { title: 'home', path: 'social.list', icon: 'mdi-home', enabled: true },
        {
          title: 'myAccount',
          path: 'social.user-profile',
          params: { query: `${this.currentUser.username}-${this.hashedId}` },
          icon: 'mi_cuenta.svg',
          enabled: true
        },
        {
          title: 'categories',
          path: 'social.categories-page',
          icon: 'categorias.svg',
          enabled: true
        },
        { title: 'messages', path: 'app.chat', params: { conversationId: '0' }, icon: 'mensajes.svg', enabled: true },
        { title: 'favorites', path: 'social.favorites', icon: 'favoritos.svg', enabled: true },
        { title: 'contact', path: 'social.contact-page', icon: 'ayuda.svg', enabled: true },
        { title: 'services', path: 'social.user-services', icon: 'servicios.svg', enabled: this.$options.JOBS_ENABLED }
      ];
    },
    getActivatedMenu (item) {
      const routeName = this.$route.name;
        if (item.path === routeName) {
          return 'title_highlight_color border-radius-small';
        }
        return '';
    },
    getTextColor (item) {
      const routeName = this.$route.name;
      if (item.path === routeName) {
        return 'text_contrast_to_background--text';
      }
      return 'main_text_color--text';
    },
    setSearchTextFromUrl () {
      if (this.$route.query.search) {
        this.updateSearchTextAction(this.$route.query.search);
        this.search = this.getSearchText;
        this.showSearch = true;
      }
    },
    setSearch () {
      this.search = this.getSearchText;
    },
    setImportedConstants () {
      this.$options.AES_SECRET = AES_SECRET;
      this.$options.USER_TYPE_CLIENT = USER_TYPE_CLIENT;
      this.$options.CALENDAR_TAB = CALENDAR_TAB;
      this.$options.USER_TYPE_ADMIN = USER_TYPE_ADMIN;
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
      this.$options.HOME_ROUTE = HOME_ROUTE;
      this.$options.SUBSCRIPTIONS_ENABLED = isFeatureEnabled(process.env.VUE_APP_RELEASE_VERSION, FEATURE_SUBSCRIPTIONS);
      this.$options.JOBS_ENABLED = isFeatureEnabled(process.env.VUE_APP_RELEASE_VERSION, FEATURE_JOBS);
    },
    sendGlobalSearch () {
        this.updateSearchTextAction(this.search);
        this.$router.push({ name: 'social.users', query: { search: this.getSearchText } });
    },
    ...mapActions({
      signOutActions: 'auth/signOut',
      updateSearchTextAction: 'search/updateSearchText'
    }),
    signOut () {
      this.signOutActions().then(() => {
        this.$router.push({ name: 'auth1.sign-in1' });
      });
    }
  }
};
</script>
<style scoped>
.prosk-navbar {
  padding: 16px 0;
}

.search-bar {
  border-radius: 100px;
}

.profile-button {
  width: 48px;
  height: 48px;
}

@media screen and (max-width: 768px) {
  .prosk-navbar {
    padding: 16px 16px;
  }
}
</style>
